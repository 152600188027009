<template>
  <div>
    <el-form :inline="true" class="form" @submit.native.prevent>
      <el-form-item>
        <el-radio-group
          v-model="filterRadio"
          @change="changeRadio"
          size="small"
        >
          <el-radio-button label="id"
            ><i class="el-icon-user-solid"> ユーザID</i></el-radio-button
          >
          <el-radio-button label="name"
            ><i class="el-icon-user"> 名前</i></el-radio-button
          >
          <el-radio-button label="phoneNumber"
            ><i class="el-icon-phone"> 電話番号</i></el-radio-button
          >
        </el-radio-group>
        <el-input
          placeholder="検索"
          :value="search"
          @input="(x) => $emit('change-search', x)"
          @change="(x) => $emit('lose-focus', x)"
          class="search-box"
        />
        <el-button
          class="search-btn"
          icon="el-icon-search"
          @click="(x) => $emit('change-query', x)"
          circle
        ></el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="users"
      @expand-change="expand"
      @selection-change="selected"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div
            v-loading="!isLoaded(props.row.id)"
            :style="{ padding: isLoaded(props.row.id) ? '0' : '2rem 0' }"
          >
            <transaction-graph
              v-if="isLoaded(props.row.id)"
              :width="50"
              :height="10"
              :data="transactions[props.row.id]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column label>
        <template slot-scope="props">
          <el-avatar :src="props.row.faceImage" :size="50" shape="square" />
        </template>
      </el-table-column>
      <el-table-column label="ユーザーID" prop="id" />
      <el-table-column label="名前" prop="name" />
      <el-table-column label="メール" prop="mail" />
      <el-table-column label="電話番号" prop="phoneNumber" />
    </el-table>
  </div>
</template>

<script>
import TransactionGraph from './TransactionGraph';

export default {
  components: {
    TransactionGraph,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    transactions: {
      type: Object,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },
  methods: {
    expand(row, expanded) {
      const isExpanded = expanded.filter((x) => x.id === row.id).length === 1;
      if (isExpanded && this.transactions[row.id] === undefined) {
        this.$emit('expand', row.id);
      }
    },
    selected(users) {
      this.$emit('selected', users);
    },
    isLoaded(id) {
      return this.transactions[id] !== undefined;
    },
    changeRadio(x) {
      this.$emit('change-radio', x);
    },
  },
  data() {
    return {
      filterRadio: 'id',
    };
  },
};
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  justify-content: flex-end;
}
.form {
  text-align: right;
}
.search-box {
  width: 12vw;
  margin-left: 2vw;
}
.search-btn {
  margin-left: 5px;
}
</style>
