<template>
  <div>
    <user-list
      :loading="loading"
      :users="usersList"
      :transactions="transactions"
      :search="search"
      @expand="(x) => $emit('expand', x)"
      @selected="(x) => $emit('selected', x)"
      @change-search="(x) => $emit('change-search', x)"
      @change-radio="(x) => $emit('change-radio', x)"
      @change-query="(x) => $emit('change-query', x)"
      @lose-focus="(x) => $emit('lose-focus', x)"
    />
    <div class="pagination-wrapper">
      <el-pagination
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="usersSize"
        @current-change="(v) => $emit('change-users-idx', v)"
      />
    </div>
  </div>
</template>

<script>
import UserList from './UserList';

const dateFilter = (dateRange) => ({ date }) => {
  if (dateRange.length < 2) {
    return true;
  }

  const [start, end] = dateRange;
  const time = new Date(date).getTime();
  return start.getTime() <= time && time <= end.getTime();
};

export default {
  components: {
    UserList,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    usersSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    transactions: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    usersList() {
      return Object.values(this.users);
    },
    filterHistory() {
      return this.history.filter(dateFilter(this.dateRange));
    },
    historyPage() {
      let table = [];
      for (
        let i = 0;
        i < this.filterHistory.length;
        i += this.historyPageSize
      ) {
        table.push(this.filterHistory.slice(i, i + this.historyPageSize));
      }
      return table;
    },
    showHistory() {
      return this.historyPage[this.historyIdx] || [];
    },
  },
  methods: {
    changeHistoryIdx(next) {
      // 入ってくる値がページネーションの値になっているので配列の引数に変えるため -1 をしている。
      this.historyIdx = next - 1;
    },
  },
  data() {
    return {
      tab: '',
      userIdx: 0,
      historyIdx: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-page-wrapper {
  display: flex;

  * {
    width: calc(50% - 0.5rem);
    box-sizing: border-box;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.pagination-wrapper {
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.history-wrapper {
  padding-top: 16px;
  display: flex;
  justify-content: center;
}
</style>
