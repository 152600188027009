<template>
  <div class="wrapper">
    <h3 id="select-qr-type-title">QRのタイプを選択してください</h3>
    <el-radio-group :value="selected">
      <el-radio @change="() => $emit('selection', 'onetime')" label="onetime"
        >一度のみの読み取り</el-radio
      >
      <el-radio @change="() => $emit('selection', 'infinite')" label="infinite"
        >制限なしの読み取り</el-radio
      >
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'use-qr',
  props: {
    selected: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#select-qr-type-title {
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 2rem;
}
</style>
