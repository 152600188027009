<template>
  <div class="success-wrapper">
    <success-animation v-if="!isFailed" class="anim" :is-stay="isStay" />
    <failed-animation v-else class="anim" />
  </div>
</template>

<script>
import SuccessAnimation from './SuccessAnimation.vue';
import FailedAnimation from './FailedAnimation.vue';

export default {
  components: { SuccessAnimation, FailedAnimation },
  props: {
    isStay: {
      type: Boolean,
      required: true,
    },
    isFailed: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.success-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.caption {
  font-size: 1rem;
  text-align: center;
}

.anim {
  min-height: 512px;
}
</style>
