<template>
  <div class="inner-wrapper">
    <el-button-group>
      <el-button
        :type="useMethod === 'user' ? 'primary' : 'default'"
        @click="$emit('change', 'user')"
        >選択したユーザー</el-button
      >
      <el-button
        :type="useMethod === 'qr' ? 'primary' : 'default'"
        @click="$emit('change', 'qr')"
        >QRコードを作成</el-button
      >
    </el-button-group>
    <div class="content">
      <use-user
        v-if="useMethod === 'user'"
        :users="users"
        @selection="(e) => $emit('select-user', e)"
      />
      <use-q-r
        v-else
        :selected="qrType"
        @selection="(e) => $emit('select-type', e)"
      />
    </div>
  </div>
</template>

<script>
import UseQR from './UseQR.vue';
import UseUser from './UseUser.vue';

export default {
  name: 'select-method',
  components: {
    UseQR,
    UseUser,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    qrType: {
      type: String,
      required: true,
    },
    useMethod: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  box-sizing: border-box;
  width: 100%;
  margin-top: 3rem;
}
</style>
