<template>
  <step-modal :range="0" :active="0" title="選択">
    <el-form>
      <el-form-item>
        <el-button type="primary" class="button" @click="$emit('collection')"
          >徴収</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="button" @click="$emit('transfer')"
          >送金</el-button
        >
      </el-form-item>
    </el-form>
  </step-modal>
</template>

<script>
import StepModal from './StepModal';

export default {
  components: { StepModal },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  font-size: 1.5rem;
}
</style>
