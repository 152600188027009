<template>
  <div class="modal-wrapper">
    <div class="title">{{ title }}</div>
    <el-steps class="steps" :active="active">
      <el-step v-for="i in [...Array(range)]" :key="i" />
    </el-steps>
    <div class="modal-content" :style="{ 'min-height': minHeight }">
      <slot />
    </div>
    <div class="modal-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    range: {
      type: Number,
      required: true,
    },
    active: {
      type: Number,
      required: true,
    },
    minHeight: {
      type: String,
      default: '512px',
    },
  },
};
</script>

<style scoped lang="scss">
.modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 3rem;
  margin: 2rem 0;
}

.steps {
  width: 80%;
}

.modal-content {
  width: 80%;
  padding: 1rem;
  box-sizing: border-box;
}

.modal-footer {
  width: 80%;
}
</style>
