<template>
  <div class="wrapper">
    <el-form label-position="top">
      <el-form-item label="徴収金額">
        <el-input
          :min="1"
          :value="amount"
          @input="(e) => $emit('changeAmount', Number(e))"
        >
          <template slot="append">{{ unit }}</template>
        </el-input>
      </el-form-item>
      <el-form-item label="発行枚数">
        <el-input
          :min="1"
          :value="sheetNum"
          @input="(e) => $emit('changeSheetNum', Number(e))"
        >
          <el-button
            slot="prepend"
            @click="() => $emit('changeSheetNum', sheetNum - 1)"
            :disabled="sheetNum <= 1"
          >
            <i class="el-icon-minus" />
          </el-button>
          <el-button
            slot="append"
            @click="() => $emit('changeSheetNum', sheetNum + 1)"
            :disabled="sheetNum > 9"
          >
            <i class="el-icon-plus" />
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    unit: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    sheetNum: {
      type: Number,
      required: true,
    },
  },
  methods: {
    log(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 30px;
}
</style>
