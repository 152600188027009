import { HorizontalBar } from 'vue-chartjs';

export default {
  extends: HorizontalBar,
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: ['送信', '受信'],
        datasets: [
          {
            backgroundColor: ['#67C23A', '#409EFF'],
            data: [this.data.s, this.data.r],
          },
        ],
      },
      {
        legend: { display: false },
        responsive: true,
        maintainAspectRation: false,
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      }
    );
    this.$refs.canvas.style.setProperty('position', 'relative', 'important');
  },
};
