<script>
import SelectMethod from './SelectMethod';
import QR from './QR';
import { AmountSetting, AmountSettingWithNum } from './AmountSetting';
import { CheckForUser, CheckForQR } from './Check';
import Success from './Success';

export default {
  props: {
    state: {
      type: String,
      require: true,
    },
    method: {
      type: String,
      require: true,
    },
    selectMethod: {
      type: Object,
      require: true,
    },
    setAmount: {
      type: Object,
      require: true,
    },
    check: {
      type: Object,
      require: true,
    },
    done: {
      type: Object,
      require: true,
    },
  },
  render(h) {
    switch (this.state) {
      case 'SELECT_METHOD':
        return h(SelectMethod, {
          props: {
            useMethod: this.method,
            ...this.selectMethod,
          },
          on: {
            change: (e) => this.$emit('changeMethod', e),
            'select-type': (e) => this.$emit('selectType', e),
            'select-user': (e) => this.$emit('selectUser', e),
          },
        });
      case 'SET_AMOUNT':
        return h(
          this.method === 'user' ? AmountSetting : AmountSettingWithNum,
          {
            props: this.setAmount,
            on: {
              changeAmount: (e) => this.$emit('changeAmount', e),
              changeSheetNum: (e) => this.$emit('changeSheetNum', e),
            },
          }
        );
      case 'CHECK':
        return h(this.method === 'user' ? CheckForUser : CheckForQR, {
          props: this.check,
        });
      case 'DONE':
        return h(this.method === 'user' ? Success : QR, {
          props: this.done,
        });
    }

    return '';
  },
};
</script>
