<template>
  <div class="success-animation-wrapper">
    <div class="circle" :class="{ 'is-stay': isStay }">
      <svg
        width="147"
        height="112"
        viewBox="0 0 147 112"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path class="check" d="M6 59.5L46.5 100L140.5 6" stroke="#fff" stroke-width="16" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isStay: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.success-animation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 300px;
  height: 300px;
  background: #6fcf97;
  border: 4px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms ease;

  &.is-stay {
    border: 4px solid #6fcf97;
    background: transparent;
    border-top-color: transparent;
    animation: loading 1s linear infinite;
    @keyframes loading {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    svg {
      display: none;
    }
  }
}

.check {
  stroke-dashoffset: 500;
  stroke-dasharray: 500;
  animation: check 2s ease;
  animation-fill-mode: forwards;

  @keyframes check {
    to {
      stroke-dashoffset: 0;
    }
  }
}
</style>
