<template>
  <el-form>
    <el-form-item>
      <el-input v-model="searchQuery" placeholder="ユーザー検索" />
    </el-form-item>
    <el-form-item>
      <el-table
        :data="filteredUsers"
        @selection-change="(e) => $emit('selection', e)"
        max-height="320px"
      >
        <el-table-column label="ユーザーID" property="id" />
        <el-table-column label="名前" property="name" />
      </el-table>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    users: {
      typs: Array,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(({ id }) => id.match(this.searchQuery));
    },
  },
};
</script>

<style scoped lang="scss"></style>
