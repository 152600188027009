<template>
  <div class="failed-animation-wrapper">
    <div class="failed" />
  </div>
</template>

<script>
export default {};
</script>

<style scope lang="scss">
.failed-animation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.failed {
  position: relative;
  width: 300px;
  height: 300px;
  background: #ff4949;
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 80%;
    height: 1rem;
    background: #fff;
    transform: scale(0, 0);
  }

  &::before {
    animation: show45 500ms ease;
    animation-fill-mode: forwards;
  }
  &::after {
    animation: show-45 500ms ease;
    animation-fill-mode: forwards;
  }

  @keyframes show45 {
    from {
      transform: translate(-50%, -50%) rotate(45deg) scale(0, 1);
    }
    to {
      transform: translate(-50%, -50%) rotate(45deg) scale(1, 1);
    }
  }
  @keyframes show-45 {
    from {
      transform: translate(-50%, -50%) rotate(-45deg) scale(0, 1);
    }
    to {
      transform: translate(-50%, -50%) rotate(-45deg) scale(1, 1);
    }
  }
}
</style>
