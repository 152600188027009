<template>
  <div class="qr-wrapper">
    <p>
      <label>取引通貨</label>
      <span>{{ target.name }}</span>
    </p>
    <p>
      <label>ポイント数</label>
      <span>{{ payment.point }} {{ target.unit }}</span>
    </p>
    <p>
      <label>QRタイプ</label>
      <span>{{
        payment.readType === 'once' ? '一度のみ有効' : '繰り返し読み込み可能'
      }}</span>
    </p>
    <p>
      <label>枚数</label>
      <span>{{ qrs.length }} 枚</span>
    </p>
    <p>
      <label>有効期限</label>
      <span>{{ yyyymmss(qrs[0].expiredAt) }}</span>
    </p>
    <p>
      <label>メッセージ</label>
      <span>{{ payment.message }}</span>
    </p>
    <p class="qr-list">
      <label>QRコードをダウンロード</label>
      <span v-for="(qr, idx) in qrs" :key="idx">
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="() => download(qr.value)"
        ></el-button>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'QR',
  props: {
    src: {
      type: String,
      required: true,
    },
    qrs: {
      type: Array,
      required: true,
    },
    payment: {
      type: Object,
      required: false,
    },
    target: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      imgData: null,
    };
  },
  methods: {
    download() {
      const anchor = document.createElement('a');
      anchor.href = this.src;
      anchor.download = 'qr.png';
      anchor.click();
    },
    yyyymmss(date) {
      if (date === undefined) {
        return '';
      }
      const yy = date.getFullYear();
      const mm = date.getMonth() + 1;
      const dd = date.getDate();
      return `${yy}/${mm}/${dd}`;
    },
  },
};
</script>

<style scoped lang="scss">
.qr-wrapper {
  padding-top: 30px;
  p {
    padding-bottom: 1rem;
    label {
      display: inline-block;
      width: 120px;
    }
  }

  .qr-list {
    label {
      display: block;
      margin-bottom: 1rem;
    }
  }
}
</style>
