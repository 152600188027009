<template>
  <div>
    <div class="button-wrapper">
      <el-popover
        v-if="isAdmin"
        placement="top-start"
        content="徴収は全体管理アカウントでは行えません"
        trigger="hover"
      >
        <template v-slot:reference>
          <el-button class="button" type="primary">徴収</el-button>
        </template>
      </el-popover>
      <el-button
        v-if="!isAdmin"
        type="primary"
        @click="collectionButtonClick"
        class="button"
        >徴収</el-button
      >
      <el-popover
        v-if="isAdmin"
        placement="top-start"
        content="送金は全体管理アカウントでは行えません"
        trigger="hover"
      >
        <template v-slot:reference>
          <el-button class="button" type="primary">送金</el-button>
        </template>
      </el-popover>
      <el-button
        v-if="!isAdmin"
        type="primary"
        @click="transferButtonClick"
        class="button"
        >送金</el-button
      >
    </div>
    <user-page
      :loading="loading.user"
      :users="displayUser"
      :usersSize="userSize"
      :transactions="userTransactionRate"
      :dateRange="dateRange"
      :search="search"
      :pageSize="limit"
      :currentPage="pageIdx"
      @expand="getTransactionRateById"
      @selected="selectedChange"
      @change-users-idx="changePageIdx"
      @change-search="changeSearch"
      @change-radio="changeRadio"
      @change-query="changeQuery"
      @lose-focus="changeQuery"
    />
    <el-dialog
      :visible.sync="transferModalVisible"
      @closed="transferReset"
      width="512px"
    >
      <transfer
        @done="() => (transferModalVisible = false)"
        @reset="transferResetClose"
      />
    </el-dialog>
    <el-dialog
      :visible.sync="collectionModalVisible"
      @closed="collectionReset"
      width="512px"
    >
      <collection
        @done="() => (collectionModalVisible = false)"
        @reset="collectionResetClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import SelectModal from '@/components/SelectModal';
import Transfer from './Transfer';
import Collection from './Collection';
import UserPage from '@/components/UserPage';
import { mapGetters, mapActions, mapState } from 'vuex';
import { ActionTypes as TransferActions } from '@/store/modules/transfer/actions';
import { ActionTypes as CollectionActions } from '@/store/modules/collection/actions';
import { ActionTypes as UserActions } from '@/store/modules/user/actions';

export default {
  components: {
    UserPage,
    Transfer,
    Collection,
    SelectModal,
  },
  data() {
    return {
      usersIdx: 0,
      search: '',
      transferModalVisible: false,
      collectionModalVisible: false,
      selectedUser: [],
      radioName: 'id',
    };
  },
  computed: {
    ...mapState(['dateRange', 'isAdmin']),
    ...mapState('user', [
      'loading',
      'userTransactionRate',
      'usersTable',
      'userSize',
      'limit',
      'offset',
      'pageIdx',
      'userId',
      'userName',
      'phoneNumber',
    ]),
    displayUser() {
      const userObj = this.usersTable[this.pageIdx - 1];
      return userObj ? Object.values(userObj) : [];
    },
  },
  methods: {
    ...mapActions('transfer', {
      transferReset: TransferActions.reset,
      setTransferUser: TransferActions.changeSelectedUsers,
      transferNextState: TransferActions.nextState,
    }),
    ...mapActions('collection', {
      collectionReset: CollectionActions.reset,
      setCollectionUser: CollectionActions.changeSelectedUsers,
      collectionNextState: CollectionActions.nextState,
    }),
    ...mapActions('user', [
      UserActions.getUsers,
      UserActions.getTransactionRateById,
      UserActions.getUserSize,
      UserActions.getUserWithOption,
      UserActions.setOffset,
      UserActions.setPageIdx,
      UserActions.setUserId,
      UserActions.setUserName,
      UserActions.setPhoneNumber,
    ]),
    done() {
      this.transferModalVisible = false;
      this.collectionModalVisible = false;
      this.getTradeHistory();
    },
    transferButtonClick() {
      if (this.selectedUser.length > 0) {
        this.setTransferUser(this.selectedUser);
        this.transferNextState();
      }
      this.transferModalVisible = true;
    },
    collectionButtonClick() {
      if (this.selectedUser.length > 0) {
        this.setCollectionUser(this.selectedUser);
        this.collectionNextState();
      }
      this.collectionModalVisible = true;
    },
    transferResetClose() {
      this.transferReset();
      this.transferModalVisible = false;
    },
    collectionResetClose() {
      this.collectionReset();
      this.collectionModalVisible = false;
    },
    changePageIdx(next) {
      this.setPageIdx(next);
      this.setOffset((next - 1) * this.limit);
      this.getUserWithOption();
    },
    changeSearch(x) {
      this.setPageIdx(1);
      this.search = x;
    },
    selectedChange(users) {
      this.selectedUser = users;
    },
    changeRadio(x) {
      this.radioName = x;
    },
    changeQuery() {
      switch (this.radioName) {
        case 'id':
          this.setUserId(this.search);
          this.setUserName('');
          this.setPhoneNumber('');
          break;
        case 'name':
          this.setUserId('');
          this.setUserName(this.search);
          this.setPhoneNumber('');
          break;
        case 'phoneNumber':
          this.setUserId('');
          this.setUserName('');
          this.setPhoneNumber(this.search);
          break;
      }
      this.getUserWithOption();
    },
  },
  async created() {
    this.getUserSize();
    await this.getUserWithOption();
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
}

.button {
  min-width: 10rem;
}
</style>
