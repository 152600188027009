<template>
  <step-modal :range="4" :active="currentStep" :title="title">
    <transfer-page
      :state="transfer.currentState"
      :method="transfer.method"
      :select-method="selectMethod"
      :set-amount="setAmount"
      :check="check"
      :done="done"
      @changeMethod="changeMethod"
      @selectType="changeQRType"
      @selectUser="changeSelectedUsers"
      @changeAmount="changeAmount"
      @changeSheetNum="changeSheetNum"
    />
    <template v-slot:footer>
      <div class="footer">
        <el-button
          v-show="transfer.currentState !== 'DONE'"
          @click="negativeAction"
          >{{ negativeLabel }}</el-button
        >
        <el-button
          type="primary"
          @click="positiveAction"
          :disabled="disabled"
          >{{ positiveLabel }}</el-button
        >
      </div>
    </template>
  </step-modal>
</template>

<script>
import StepModal from '@/components/StepModal';
import TransferPage from '@/components/Transfer';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ActionTypes as TransferActions } from '@/store/modules/transfer/actions';
import { ActionTypes as RootActions } from '@/store/actions';

export default {
  name: 'transfer',
  components: {
    StepModal,
    TransferPage,
  },
  computed: {
    ...mapState(['transfer', 'currencyInfo']),
    ...mapGetters('transfer', ['usersList']),
    selectMethod() {
      return {
        users: this.transfer.selectedUsers,
        qrType: this.transfer.qrType,
      };
    },
    setAmount() {
      return {
        unit: this.currencyInfo ? this.currencyInfo.unit : 'pt',
        amount: this.transfer.amount,
        sheetNum: this.transfer.sheetNum,
      };
    },
    check() {
      return {
        users: this.transfer.selectedUsers,
        amount: this.transfer.amount,
        unit: this.currencyInfo ? this.currencyInfo.unit : 'pt',
        balance: this.transfer.orgAmount,
        sheetNum: this.transfer.sheetNum,
        qrType: this.transfer.qrType,
      };
    },
    done() {
      return {
        isStay: this.transfer.sending,
        src: this.transfer.qrSrc,
        isFailed: this.transfer.isFailed,
        qrs: this.transfer.qrs,
        target: this.transfer.targetCurrency,
        payment: this.transfer.payment,
      };
    },
    currentStep() {
      switch (this.transfer.currentState) {
        case 'SELECT_METHOD':
          return 0;
        case 'SET_AMOUNT':
          return 1;
        case 'CHECK':
          return 2;
        case 'DONE':
          return 3;
      }
      return 0;
    },
    title() {
      switch (this.transfer.currentState) {
        case 'SELECT_METHOD':
          return '送金方法';
        case 'SET_AMOUNT':
          return '金額設定';
        case 'CHECK':
          return '確認';
        case 'DONE':
          return this.transfer.isFailed ? '送金失敗' : '完了';
      }
      return '';
    },
    disabled() {
      switch (this.transfer.currentState) {
        case 'SELECT_METHOD':
          return (
            this.transfer.method === 'user' && this.transfer.users.length === 0
          );
      }

      return false;
    },
    positiveLabel() {
      return this.transfer.currentState === 'DONE' ? '完了' : '次へ';
    },
    negativeLabel() {
      return this.transfer.currentState === 'SELECT_METHOD'
        ? 'キャンセル'
        : '戻る';
    },
  },
  methods: {
    ...mapActions('transfer', [
      TransferActions.getUsers,
      TransferActions.getUserSize,
      TransferActions.changeMethod,
      TransferActions.changeQRType,
      TransferActions.changeSelectedUsers,
      TransferActions.changeAmount,
      TransferActions.changeSheetNum,
      TransferActions.nextState,
      TransferActions.prevState,
      TransferActions.reset,
      TransferActions.getOrgAmount,
    ]),
    ...mapActions([RootActions.getCurrencyInfo]),
    positiveAction() {
      if (this.transfer.currentState === 'DONE') {
        this.$emit('done');
      } else {
        this.nextState();
      }
    },
    negativeAction() {
      if (this.transfer.currentState === 'SELECT_METHOD') {
        this.$emit('reset');
      } else {
        this.prevState();
      }
    },
  },
  async mounted() {
    this.getCurrencyInfo();
    this.getOrgAmount();
    await this.getUserSize();
    this.getUsers();
  },
};
</script>

<style></style>
