<template>
  <step-modal :range="4" :active="currentStep" :title="title">
    <collection-page
      :state="collection.currentState"
      :method="collection.method"
      :select-method="selectMethod"
      :set-amount="setAmount"
      :check="check"
      :done="done"
      @changeMethod="changeMethod"
      @selectType="changeQRType"
      @selectUser="changeSelectedUsers"
      @changeAmount="changePoint"
      @changeSheetNum="changeSheetNum"
    />
    <template v-slot:footer>
      <div class="footer">
        <el-button
          v-show="collection.currentState !== 'DONE'"
          @click="negativeAction"
          >{{ negativeLabel }}</el-button
        >
        <el-button
          type="primary"
          @click="positiveAction"
          :disabled="disabled"
          >{{ positiveLabel }}</el-button
        >
      </div>
    </template>
  </step-modal>
</template>

<script>
import StepModal from '@/components/StepModal';
import CollectionPage from '@/components/Collection';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ActionTypes as CollectionActions } from '@/store/modules/collection/actions';
import { ActionTypes as RootActions } from '@/store/actions';

export default {
  name: 'collection',
  components: {
    StepModal,
    CollectionPage,
  },
  computed: {
    ...mapState(['collection']),
    ...mapState(['currencyInfo']),
    ...mapGetters('collection', ['usersList']),
    selectMethod() {
      return {
        users: this.collection.selectedUsers,
        qrType: this.collection.qrType,
      };
    },
    setAmount() {
      return {
        unit: this.currencyInfo ? this.currencyInfo.unit : 'pt',
        amount: this.collection.point,
        sheetNum: this.collection.sheetNum,
      };
    },
    check() {
      return {
        users: this.collection.selectedUsers,
        amount: this.collection.point,
        unit: this.currencyInfo ? this.currencyInfo.unit : 'pt',
        balance: this.collection.orgAmount,
        sheetNum: this.collection.sheetNum,
        qrType: this.collection.qrType,
      };
    },
    done() {
      return {
        isStay: this.collection.sending,
        src: this.collection.qrSrc,
        isFailed: this.collection.isFailed,
        qrs: this.collection.qrs,
        target: this.collection.targetCurrency,
        payment: this.collection.payment,
      };
    },
    currentStep() {
      switch (this.collection.currentState) {
        case 'SELECT_METHOD':
          return 0;
        case 'SET_AMOUNT':
          return 1;
        case 'CHECK':
          return 2;
        case 'DONE':
          return 3;
      }
      return 0;
    },
    title() {
      switch (this.collection.currentState) {
        case 'SELECT_METHOD':
          return '徴収方法';
        case 'SET_AMOUNT':
          return '金額設定';
        case 'CHECK':
          return '確認';
        case 'DONE':
          return this.collection.isFailed ? '送金失敗' : '完了';
      }
      return '';
    },
    disabled() {
      switch (this.collection.currentState) {
        case 'SELECT_METHOD':
          return (
            this.collection.method === 'user' &&
            this.collection.users.length === 0
          );
      }

      return false;
    },
    positiveLabel() {
      return this.collection.currentState === 'DONE' ? '完了' : '次へ';
    },
    negativeLabel() {
      return this.collection.currentState === 'SELECT_METHOD'
        ? 'キャンセル'
        : '戻る';
    },
  },
  methods: {
    ...mapActions('collection', [
      CollectionActions.getUsers,
      CollectionActions.getUserSize,
      CollectionActions.changeMethod,
      CollectionActions.changeQRType,
      CollectionActions.changeSelectedUsers,
      CollectionActions.changePoint,
      CollectionActions.changeMessage,
      CollectionActions.changeSheetNum,
      CollectionActions.nextState,
      CollectionActions.prevState,
      CollectionActions.reset,
      CollectionActions.getOrgAmount,
    ]),
    ...mapActions([RootActions.getCurrencyInfo]),
    positiveAction() {
      if (this.collection.currentState === 'DONE') {
        this.$emit('done');
      } else {
        this.nextState();
      }
    },
    negativeAction() {
      if (this.collection.currentState === 'SELECT_METHOD') {
        this.$emit('reset');
      } else {
        this.prevState();
      }
    },
  },
  async mounted() {
    this.getCurrencyInfo();
    this.getOrgAmount();
    await this.getUserSize();
    await this.getUsers();
  },
};
</script>

<style></style>
