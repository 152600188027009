<template>
  <div>
    <check-item caption="徴収方法">
      <div class="method">
        <div>選択したユーザー</div>
        <el-input type="textarea" :readonly="true" :rows="3" :value="userIDs" />
      </div>
    </check-item>
    <check-item caption="徴収額">
      <div>{{ amount }} {{ unit }} / 1人</div>
    </check-item>
    <check-item caption="会計">
      <div class="sum">
        <div>
          <div>組織残高</div>
          <div>{{ balance }} {{ unit }}</div>
        </div>
        <div>
          <div>総徴収額</div>
          <div>{{ sumGetAmount }} {{ unit }}</div>
        </div>
        <span class="line" />
        <div>
          <div>徴収後組織残高</div>
          <div>{{ beforeBalance }} {{ unit }}</div>
        </div>
      </div>
    </check-item>
  </div>
</template>

<script>
import CheckItem from './CheckItem.vue';

export default {
  components: { CheckItem },
  props: {
    users: {
      type: Array,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
  },
  computed: {
    userIDs() {
      return this.users.map((x) => x.id).join('\n');
    },
    sumGetAmount() {
      return this.amount * this.users.length;
    },
    beforeBalance() {
      return this.balance + this.sumGetAmount;
    },
  },
};
</script>

<style scoped lang="scss">
.method {
  display: flex;
  flex-direction: column;

  * {
    margin-bottom: 1rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.sum {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    padding: 0 1rem;

    div {
      min-width: 50%;
    }

    div:last-child {
      text-align: right;
    }
  }

  .line {
    height: 1px;
    background: black;
    margin: 1rem 0;
  }
}
</style>
