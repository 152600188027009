<template>
  <div>
    <check-item caption="徴収方法">QRコードを作成</check-item>
    <check-item caption="QRタイプ">
      <span v-if="qrType === 'infinite'">制限なしの読み取り</span>
      <span v-else>一度のみの読み取り</span>
    </check-item>
    <check-item caption="徴収額">{{ amount }} {{ unit }} / 1枚</check-item>
  </div>
</template>

<script>
import CheckItem from './CheckItem.vue';

export default {
  components: {
    CheckItem,
  },
  props: {
    amount: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    qrType: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scope lang="scss"></style>
