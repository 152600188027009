<template>
  <el-form>
    <el-form-item>
      <div class="caption">送金金額</div>
      <el-input
        type="number"
        :min="1"
        :value="amount"
        @input="e => $emit('changeAmount', Number(e))"
      >
        <template slot="append">{{ unit }}</template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    unit: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.caption {
  padding: 3rem 0;
  font-size: 1rem;
}
</style>
