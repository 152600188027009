<template>
  <div class="item-wrapper">
    <div class="caption">{{caption}}</div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.item-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 128px;
}

.caption {
  padding: 0.5rem 0;
  font-size: 1rem;
}

.content {
  padding-left: 2rem;
}
</style>
